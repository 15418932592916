.containers {
  position: relative;
}
.sidebar {
  background: #1c2430;
  transition: all 0.5s;
  max-width: 1000px;
  width: 230px;
  height: 100vh;
}
/* .sidebar.collapsed {
  max-width: 0;
  overflow: hidden;
  border-right: 0;
} */
.content {
  position: absolute;
  display: none;
}
@media screen and (max-width: 950px) {
  .content {
    display: block;
    z-index: 2;
  }
  .sidebar{
    max-width: 0;
    overflow: hidden;
    border-right: 0;
    position: absolute;
  }
  .sidebar.collapsed {
    position: absolute;
    z-index: 1;
    background: #1c2430;
    transition: all 0.5s;
    max-width: 1000px;
    width: 230px;
    height: 100vh;
  }
}
.content button {
  margin: 16px;
  background-color: #0d6efd;
  border: none;
  border-radius: 2px;
}
.content button div {
  width: 20px;
  height: 3px;
  background-color: aliceblue;
  margin: 3px 0;
}

#head {
  display: flex;
  justify-content: center;
  position: relative;
  background: #0d1117;
  padding-top: 9px;
}

#head .logo {
  font-size: 22px;
  font-weight: 700;
  /* font-style: italic; */
  color: aliceblue;
}

.lists {
  list-style: none;
  padding-left: 0px;
}

.lists li {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lists li:hover {
  border-radius: 5px;
  background-color: #003575;
  cursor: pointer;
}

.lists span {
  text-decoration: none;
  color: aliceblue;
  /* padding: 5px 35px; */
  font-size: 20px;
  text-align: center;
  /* font-weight: bold; */
}

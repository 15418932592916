.loginPage {
  height: 100vh;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  background: #cbe0ff;
}
.loginPage form {
  max-width: 500px;
  border: 1px solid #aaaaaa;
  padding: 4rem;
  background: #dfecff;
  border-radius: 7px;
  box-shadow: 0 0 5px #b6b6b6;
  width: 500px;
  height: 414px;
}
.loginPage form button {
  width: 100%;
}
.sub-header {
  height: 58px;
  background: #e4e4e4;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headerHeading{
  display: none;
}
.userTab {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid gray;
}
.dropdown-toggle::after {
  content: revert-layer !important;
}
.dropdown-menu {
  /* padding: 10px; */
  width: 200px;
  font-weight: bold;
  color: rgb(87, 87, 87);
}
.logoutBtn {
  cursor: pointer;
  border-top: 1px solid #b4b2b2;
  margin-top: 8px;
}
.dropdown-menu li {
  padding-left: 10px;
}
.addLinesButton {
  display: flex;
  justify-content: end;
}
.linesAlert {
  margin-top: 5px;
  font-size: 13px;
  color: red;
}
table th,
table td {
  padding: 10px 25px;
  text-align: center;
  vertical-align: middle;
}
table thead {
  color: white;
  background: #003575;
}
.buy-hd-tab {
  border-right: 2px solid white;
}
.buy-bd-tab {
  border-right: 2px solid black;
}
.scrollable-td {
  max-width: 250px; /* Adjust the width as needed */
  overflow-x: auto;
  white-space: nowrap;
}

.scrollable-content {
  display: inline-block;
  min-width: 100%; /* Ensure the content is at least as wide as the parent */
}
@media screen and (max-width: 600px) {
  .loginPage form {
    max-width: max-content;
    width: max-content;
  }
}
@media screen and (max-width: 950px) {
  .headerHeading{
    display: block;
  }
}

